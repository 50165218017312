
.home-container{
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    height: auto;
    flex-wrap: wrap;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70px;
    border-right: 3px solid #FEDE00;
}

.links a img {
    max-width: 80%;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.links a img:hover {
    transform: scale(1.1);
}

.img-container, .greeting-container {
    flex: 1;
    display: flex;
}

.greeting-container {
    flex-direction: column;
    height: 100%;
}

.greeting, .name, .title {
    opacity: 0;
    transform: translateY(-100%);
    animation: slideIn 1s forwards ease-in-out;
}

.greeting {
    font-size: 3.5rem;
    margin-bottom: 10px;
}

.name {
    font-size: 3rem;
    margin-bottom: 10px;
}

.title {
    font-size: 2.5rem;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.img-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container img {
    max-width: 80%;
    height: auto;
    object-fit: contain;
    border-radius: 50px;
}

@media (max-width: 768px) {
    .home-container {
        flex-direction: column;
        margin-top: 20px;
    }

    .greeting-container{
        margin-top: 20px;
        align-items: center;
        justify-content: flex-start;
    }

    .links {
        width: 120px;
        flex-direction: row;
        gap: 15px;
        margin-top: auto;
        justify-content:right;
        border-bottom: 3px solid #FEDE00;
        border-right: none;
        padding-bottom: 10px;
    }

    .greeting {
        font-size: 2rem;
    }

    .name {
        font-size: 1.75rem;
    }

    .title {
        font-size: 1.5rem;
    }

    .img-container {
        margin-top: 20px;
    }

    .img-container img {
        max-width: 60%;
    }
}

@media (max-width: 480px) {
    .home-container {
        flex-direction: column;
        margin-top: 10px;
    }

    .greeting-container {
        margin-top: 30px;
        align-items: center;
    }

    .links {
        flex-direction: row;
        margin-top: auto;
        gap: 10px;
        width: 100px;
    }

    .greeting {
        font-size: 1.5rem;
    }

    .name {
        font-size: 1.25rem;
    }

    .title {
        font-size: 1rem;
    }

    .img-container {
        margin-top: 30px;
    }

    .img-container img {
        max-width: 70%;
    }
}