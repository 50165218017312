
.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: #DBE8D8;
}

header {
    text-align: center;
    font-size: 2rem;
    border-bottom: 3px solid #FEDE00;
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: #032b0f;
}


main {
    flex: 1; 
    padding: 20px;
    padding-top: 150px; 
    background-color: #31533c;
}

li a {
    text-decoration: none;
    color: #DBE8D8;
    font-size: 2.5rem;
}

li a:hover {
    text-decoration: none;
    border-bottom: 2px solid #FEDE00;
}

footer {
    text-align: center;
    font-size: 1rem;
    border-top: 3px solid #FEDE00;
    min-height: 60px;
    width: 100%; 
    background-color: #032b0f;
}


@media (max-width: 768px) {
    header, footer {
        font-size: 1.5rem;
    }

    main {
        padding: 15px;
        margin-top: 100px;
    }
}

@media (max-width: 480px) {
    header{
        font-size: 1.2rem;
    }

    main {
        padding: 10px;
    }

    footer {
        font-size: 1rem;
    }
}

