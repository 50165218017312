nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

nav img {
    border-radius: 50%;
    margin: 10px;
    width: 100px;
    height: 100px;
}

.nav {
    list-style: none;
    display: flex;
    margin-right: 50px;
    padding: 5px;
}

.nav li {
    margin: 0 15px;
}


@media (max-width: 768px) {
    nav {
        padding: 10px;
    }

    nav img {
        width: 40px;
        height: 40px;
    }

    .nav {
        margin-right: 0;
    }

    .nav li {
        margin: 0 10px;
    }

    .nav li a {
        font-size: 1.25rem;
    }
}


@media (max-width: 480px) {
    nav img {
        width: 60px;
        height: 60px;
    }

    .nav li a {
        font-size: 1rem;
        margin-left: 10px;
    }

    .nav {
        margin-right: 0;
    }

    .nav li {
        margin: 8px 0;
    }
}