.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.about {
    width: 95%;
}

.about-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.about, .skill-container, .certifications, .details {
    padding: 20px;
    background-color: #032b0f; 
    border: 2px groove #DBE8D8; 
    border-radius: 10px; 
    box-shadow: 0 4px 10px rgba(0,0,0,0.5);
    text-align: center;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.about p {
    color: #DBE8D8; 
    font-size: 1.5rem; 
    line-height: 1.6; 
}


.skill-container, .certifications, .details {
    padding: 20px;
    width: 95%;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}


.skills, .details-content {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
}

.skills > div, .certifications > div, .details-content > .detail-item {
    background-color:  #074119;
    border: 2px solid #DBE8D8;
    border-radius: 10px;
    padding: 20px;
    width: 45%;
    box-shadow: 0 4px 10px rgba(0,0,0,0.5);
    transition: transform 0.3s ease;
}

.skills > div:hover, .certifications > div:hover, .details-content > .detail-item:hover {
    transform: translateY(-5px);
}

.skills h2 {
    margin-bottom: 15px;
    color: #DBE8D8;
    font-size: 2rem;
}

.skills ul, .certifications ul{
    list-style-type: none;
    padding: 0;
}

.skills li {
    margin-bottom: 10px;
    font-size: 1rem;
    padding: 8px 12px;
    background-color: #2c6b41;
    border-radius: 5px;
    border: 1px solid #DBE8D8;
}

.certifications {
    align-items: center;
}

.certifications ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    list-style-type: none;
}

.certifications a {
    font-size: 1.5rem;
    display: inline-block;
    padding: 10px 15px;
    margin-bottom: 10px;
    background-color: #04160a;
    border-radius: 5px;
    border: 1px solid #DBE8D8;
    cursor: pointer;
    transition: background-color 0.3s ease border-color 0.3s ease;
}

.certifications a:hover {
    background-color: #146b30;
    border-color: #DBE8D8;
    color: #FEDE00;
}

@media (max-width: 768px) {
    .about-container {
        padding: 15px;
    }

    .about-container h1 {
        font-size: 2.5rem; 
    }

    .about, .skill-container, .certifications, .details {
        width: 90%;
    }

    .skills, .certifications, .details-content {
        flex-direction: column;
        align-items: center;
    }

    .skills > div, .certifications > div, .details-content > .detail-item {
        width: 100%;
        max-width: 500px; 
    }
}

@media (max-width: 480px) {
    .about-container {
        padding: 10px;
    }

    .about-container h1 {
        font-size: 2rem; 
    }

    .about {
        width: 95%; 
    }

    .about p {
        font-size: 1.2rem;
    }

    .skill-container, .certifications, .details {
        width: 95%; 
    }

    .skills > div, .certifications > div, .details-content > .detail-item {
        width: 90%; 
        max-width: none; 
    }
}


