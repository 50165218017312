.projects {
    display: flex;
    flex-direction: column;
    padding: 20px;
}


.projects > div {
    max-width:100%;
    margin-bottom: 20px;
    padding: 20px;
    border: 2px groove #DBE8D8;
    border-radius: 10px;
    background-color: #032b0f; 
}

.projects a {
    color: #DBE8D8;
    text-decoration: none;
    border-bottom: 2px solid #FEDE00;
}

.projects a:hover {
    color: #FEDE00;
}


.tech-container {
    margin-top: 10px;
}

.tech-details {
    border: 1px solid #DBE8D8;
    padding: 10px;
    border-radius: 5px;
    background-color: #074119; 
}

.tech-container ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left: 0;
}

.tech-container li {
    margin-right: 15px;
    margin-bottom: 5px;
    padding: 8px 12px;
    background-color: #2c6b41;
    border-radius: 5px;
    border: 1px solid #DBE8D8;
}

.more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.more a {
    display: inline-block;
    padding: 10px 15px; 
    background-color: #04160a; 
    border-radius: 5px; 
    border: 1px solid #DBE8D8; 
    color: #DBE8D8; 
    text-decoration: none; 
    cursor: pointer; 
    transition: background-color 0.3s ease, border-color 0.3s ease; 
}

.more a:hover {
    background-color: #146b30;
    border-color: #DBE8D8; 
}


@media (max-width: 768px) {
    .projects {
        align-items: center; 
    }

    .projects > div {
        width: 100%; 
        margin-bottom: 15px;
        padding: 15px;
    }

    .tech-details li {
        margin-right: 0; 
        margin-bottom: 10px;
        padding: 8px 10px; 
        width: 100%; 
        text-align: center;
        font-size: 1rem;
    }

    .more {
        align-items: center; 
    }

    .more a {
        padding: 8px 12px; 
        font-size: 0.9em; 
        text-align: center; 
    }
}


@media (max-width: 480px) {
    .projects > div {
        padding: 10px; 
    }

    .tech-details li {
        margin-bottom: 10px;
    }

    .more a {
        padding: 6px 10px;
        font-size: 0.8em; 
    }
}