.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    margin: 0;
}

.form-container {
    max-width: 500px;
    width: 100%;
    padding: 20px;
    background-color: #032b0f;
    border-radius: 10px;
    border: 2px groove #DBE8D8;
}

.form {
    margin-bottom: 15px;
}

.form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #DBE8D8;
    box-sizing: border-box;
}

.form textarea {
    width: 99%;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #DBE8D8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    align-self: center;
    width: 100%;
    margin-top: 10px;
}

.submit-btn:hover {
    background-color: #0056b3;
}


@media (max-width: 768px) {
    .form-container {
        max-width: 90%; 
    }

    .form input {
        padding: 8px; 
    }

    .submit-btn {
        padding: 10px 15px; 
    }
}


@media (max-width: 480px) {
    .contact-container {
        height: auto;
        padding: 20px; 
    }

    .form-container {
        max-width: 100%; 
        padding: 15px;
    }

    .form input {
        padding: 8px;
        font-size: 0.9rem; 
    }

    .submit-btn {
        padding: 10px 15px; 
        font-size: 1rem; 
    }
}